import React, { FC } from 'react';
import { abbreviations, formatCurrency, formatPercent } from '../../../utils/formatters';
import {
    DELTA_DURATION,
    DELTA_SUM,
    END_TERM_DEPOSIT_ID,
    MAX_REPLENISHMENT,
    MIN_REPLENISHMENT,
    PER_MONTH_DEPOSIT_ID,
    STEP_REPLENISHMENT,
} from '../../../store/constants';
import { SelectDepositType } from './SelectDepositType';
import { Input, styled } from '@mui/material';
import { isNumber, isUndefined } from 'lodash';
import { depositCalculationsStore } from '../../../store/depositCalculationsStore';
import { Slider } from '../../../components/Slider';
import { observer } from 'mobx-react';
import { Text } from '../../../components/Text';
import { BeveledContainer } from '../../../components/BeveledContainer';

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
}));

const SharePercent = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 30,
});

const AddText = ({ value }: { value: number }) => (
    <Text variant="Body" color="primary">{`Для открытия вклада на сумму свыше ${formatCurrency(
        value
    )} обратитесь к сотруднику компании`}</Text>
);

type Props = {
    disabled?: boolean;
};

export const CreateDepositOptions: FC<Props> = observer(({ disabled = false }) => {
    const disabledSlider = !depositCalculationsStore.depositType || disabled;

    return (
        <Root>
            <Text color="secondary">Укажите название Инвесткопилки:</Text>
            <BeveledContainer>
                <Input
                    value={depositCalculationsStore.alias}
                    onChange={e => {
                        depositCalculationsStore.setAlias(e.target.value);
                    }}
                    disableUnderline
                    placeholder='Название Инвесткопилки, например "На автомобиль"'
                    sx={{
                        '& ::placeholder': {
                            color: 'black',
                            opacity: 0.87,
                        },
                    }}
                />
            </BeveledContainer>
            <Text color="secondary">Выберите параметры нового вклада</Text>
            <SelectDepositType disabled={disabled} />
            <Slider
                id="sum"
                value={depositCalculationsStore.sum || 0}
                setValue={value => depositCalculationsStore.setSum(value)}
                min={depositCalculationsStore.minSum}
                max={depositCalculationsStore.maxSum}
                step={DELTA_SUM}
                label="Сумма вклада"
                formatValue={value => (isNumber(value) ? formatCurrency(value) : undefined)}
                disabled={disabledSlider || isUndefined(depositCalculationsStore.minSum)}
                additionalInfo={
                    depositCalculationsStore.needSumNotice ? (
                        <AddText value={depositCalculationsStore.maxSum || 0} />
                    ) : undefined
                }
            />
            <Slider
                id="duration"
                value={depositCalculationsStore.duration || 0}
                setValue={value => depositCalculationsStore.setDuration(value)}
                min={depositCalculationsStore.minDuration}
                max={depositCalculationsStore.maxDuration}
                step={DELTA_DURATION}
                label="Срок инвестирования, месяцев"
                disabled={disabledSlider || isUndefined(depositCalculationsStore.minDuration)}
            />
            <Slider
                id="replenishment"
                value={depositCalculationsStore.replenishment || 0}
                setValue={value => depositCalculationsStore.setReplenishment(value)}
                min={MIN_REPLENISHMENT}
                max={MAX_REPLENISHMENT}
                step={STEP_REPLENISHMENT}
                label="Пополнение вклада"
                formatValue={value => (isNumber(value) ? `${formatCurrency(value)} / месяц` : undefined)}
                disabled={disabledSlider}
            />
            {depositCalculationsStore.depositType?.id === PER_MONTH_DEPOSIT_ID && (
                <>
                    <Text color="secondary">Расчёт потенциальной доходности продукта:</Text>
                    <BeveledContainer>
                        <SharePercent>
                            <Text variant="Title">Ежемесячный доход</Text>
                            <Text variant="TitleBold" color="primary">
                                {formatCurrency(depositCalculationsStore.perMonthDepositInfo?.perMonthIncome)}
                            </Text>
                        </SharePercent>
                    </BeveledContainer>

                    <BeveledContainer>
                        <SharePercent>
                            <Text variant="Title">Доход за весь срок</Text>
                            <Text variant="TitleBold" color="primary">
                                {formatCurrency(depositCalculationsStore.perMonthDepositInfo?.wholeIncome)}
                            </Text>
                        </SharePercent>
                    </BeveledContainer>
                </>
            )}

            {depositCalculationsStore.depositType?.id === END_TERM_DEPOSIT_ID && (
                <>
                    <Text color="secondary">Расчёт потенциальной доходности продукта:</Text>
                    <BeveledContainer>
                        <SharePercent>
                            <Text variant="Title">Итого без копилки</Text>
                            <Text variant="TitleBold" color="primary">
                                {formatCurrency(depositCalculationsStore.endTermDepositInfo?.incomeWithoutPercents)}
                            </Text>
                        </SharePercent>
                    </BeveledContainer>

                    <BeveledContainer>
                        <SharePercent>
                            <Text variant="Title">Итого с копилкой</Text>
                            <Text variant="TitleBold" color="primary">
                                {formatCurrency(depositCalculationsStore.endTermDepositInfo?.income)}
                            </Text>
                        </SharePercent>
                    </BeveledContainer>

                    <BeveledContainer>
                        <SharePercent>
                            <Text variant="Title">Копилка добавила</Text>
                            <Text variant="TitleBold" color="primary">
                                {formatCurrency(depositCalculationsStore.endTermDepositInfo?.diff)}
                            </Text>
                        </SharePercent>
                    </BeveledContainer>
                </>
            )}
        </Root>
    );
});
