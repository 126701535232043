import { Box } from '@mui/material';
import React from 'react';
import { Text } from '../../../components/Text';

export const ConfirmCreateDepositOptions = () => {
    return (
        <Box>
            <Text>Текст: характеристики продукта</Text>
            <Text>Текст: характеристики продукта</Text>
            <Text>Текст: характеристики продукта</Text>
            <Text>Текст: характеристики продукта</Text>
        </Box>
    );
};
